
import icon_logo from '../../static/pics/sr-logo.png';

function Footer() {
	return (
		<footer className="s-footer">
			<div className="container">
				<div className="row">

					<div className="col-12 col-md-auto">
						<div className="s-footer__logo">
							<img src={ icon_logo } alt="" />
						</div>
					</div>

					<div className="col-12 col-md-auto">
						<ul className="s-footer__menu">
							<li> <a href="https://envelop.is" target="_blank" rel="noopener noreferrer">powered by <span className="text-green">Envelop</span></a></li>
						</ul>

					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;