
import config       from '../../config.json';
import Web3         from 'web3';
import { Contract } from "web3-eth-contract";
import {
	ERC20Contract,
	ERC20ContractParamsType,
	MetamaskAdapter
} from '.';

import BigNumber from 'bignumber.js';
import {
	setUrlNotFound,
	updateOneWrappedToken,
	updateWrappedTokens
} from '../../reducers';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export type WrappedTokenType = {
	tokenId            : string,
	backedERC20Value   : Array<{amount: BigNumber, address: string, apy: BigNumber, reward: BigNumber}>,
	unwrapAfter        : BigNumber | undefined,
}

type WrappedTokenDispatcherPropsType = {
	store                   : any,
	web3                    : Web3,
	userAddress             : string,
	wrapperContractAddress  : string,
	wrapperContractAux      : Array<{ url: string, address: string }>;
	erc20Tokens             : Array<ERC20ContractParamsType>,
	metamaskAdapter         : MetamaskAdapter,
}

export default class WrappedTokenDispatcher {

	store                      : any;
	web3                       : Web3;
	userAddress                : string;
	wrapperContractAddress     : string;
	wrapperContractAux         : Array<{ url: string, address: string }>;
	wrapperContract!           : Contract;
	erc20Tokens                : Array<ERC20ContractParamsType>;
	tradableERC20TokenContract!: ERC20Contract;
	metamaskAdapter            : MetamaskAdapter;

	tokensOnPage            : number;
	tokenBalance            : number;
	lastFetchedUsersIdx     : number;
	removedUsersIdx         : Array<number>;

	tokensLoading: boolean;

	constructor(props: WrappedTokenDispatcherPropsType) {
		this.store                    = props.store;
		this.web3                     = props.web3;
		this.userAddress              = props.userAddress;
		this.wrapperContractAux       = props.wrapperContractAux;
		this.erc20Tokens              = props.erc20Tokens;
		this.metamaskAdapter          = props.metamaskAdapter;
		this.tokensOnPage             = (config as any).tokensOnPage;
		this.tokenBalance             = 0;
		this.lastFetchedUsersIdx      = 0;
		this.removedUsersIdx          = [];
		this.tokensLoading            = false;

		let wrapperABI;
		const url = window.location.hash.replaceAll('#', '').replaceAll('/', '');
		const foundWrapperContract = this.wrapperContractAux.filter((item) => { return item.url.toLowerCase() === url.toLowerCase() });
		if ( foundWrapperContract.length ) {
			try {
				wrapperABI = require(`../../abis/${foundWrapperContract[0].address}.json`);
			} catch(e) {
				console.log(`Cannot load ${foundWrapperContract[0].address} wrapper abi:`, e);
				throw new Error(`Cannot load wrapper abi`);
			}
			this.wrapperContractAddress = foundWrapperContract[0].address;
			this.wrapperContract = new this.web3.eth.Contract(wrapperABI, foundWrapperContract[0].address);
		} else {

			if ( url ) {
				this.store.dispatch(setUrlNotFound());
			}

			try {
				wrapperABI = require(`../../abis/${props.wrapperContractAddress}.json`);
			} catch(e) {
				console.log(`Cannot load ${props.wrapperContractAddress} wrapper abi:`, e);
				throw new Error(`Cannot load wrapper abi`);
			}
			this.wrapperContractAddress = props.wrapperContractAddress;
			this.wrapperContract = new this.web3.eth.Contract(wrapperABI, props.wrapperContractAddress);
		}
		console.log('wrapperContract', this.wrapperContract);

		this.addCheckoutEventListener();
	}
	addCheckoutEventListener() {
		this.wrapperContract.events.Staked(
			{
				fromBlock: 'earliest'
			},
			(e: any, data: any) => {
				this.metamaskAdapter.updateBalances();
			}
		);
		this.wrapperContract.events.Harvest(
			{
				fromBlock: 'earliest'
			},
			(e: any, data: any) => {
				this.metamaskAdapter.updateBalances();
			}
		);
		this.wrapperContract.events.UnWrapped(
			{
				fromBlock: 'earliest'
			},
			(e: any, data: any) => {
				this.metamaskAdapter.updateBalances();
			}
		);
	}

	async createERC20Contract() {
		const ERC20Address = await this.wrapperContract.methods.defaultFarmingToken().call()
		this.tradableERC20TokenContract = new ERC20Contract({
			web3            : this.web3,
			store           : this.store,
			contractAddress : ERC20Address,
			userAddress     : this.userAddress,
			erc20Type       : '',
			allowanceAddress: this.wrapperContractAddress,
			wrapperContract : this,
			metamaskAdapter : this.metamaskAdapter,
		});
		await this.tradableERC20TokenContract.getParams();

		this.getNFTTokens();

		return this.tradableERC20TokenContract;
	}

	async getNFTTokenById(contract: Contract, tokenId: number): Promise<WrappedTokenType> {
		const contractTokenId = await contract.methods.tokenOfOwnerByIndex(this.userAddress, tokenId).call();

		const wrappedToken = await contract.methods.getWrappedToken(contractTokenId).call();
		const wrappedERC20 = await contract.methods.getERC20Collateral(contractTokenId).call();
		const wrappedERC20Parsed: Array<{amount: BigNumber, address: string, apy: BigNumber, reward: BigNumber}> = await Promise.all(wrappedERC20.map(async (item: any) => {
			const apy = new BigNumber(await contract.methods.getPlanAPYByTokenId(contractTokenId, item.erc20Token).call()).dividedBy(100);
			const reward = new BigNumber(await contract.methods.getAvailableRewardAmount(contractTokenId, item.erc20Token).call());
			return {
				amount: new BigNumber(item.amount),
				address: item.erc20Token,
				apy,
				reward
			}
		}));

		return {
			tokenId            : `${contractTokenId}`,
			backedERC20Value   : wrappedERC20Parsed,
			unwrapAfter        : new BigNumber(wrappedToken.unwrapAfter).multipliedBy(1000),
		}

	}
	async getNFTTokens() {
		if ( this.tokensLoading ) { return; }
		this.tokensLoading = true;
		this.store.dispatch(updateWrappedTokens([]));
		const balance = await this.wrapperContract.methods.balanceOf( this.userAddress ).call();

		for (let idx = 0; idx < balance; idx++) {
			const token       = await this.getNFTTokenById(this.wrapperContract, idx);
			this.store.dispatch(updateOneWrappedToken(token));
		};
		this.tokensLoading = false;
	}

	async wrapForFarming(erc20TokenAddress: string, erc20TokenValue: BigNumber, period: BigNumber) {

		const tx = this.wrapperContract.methods.WrapForFarming(
			this.userAddress,
			[erc20TokenAddress, erc20TokenValue.toString()],
			period.toFixed(0)
		);

		try {
			await tx.estimateGas({ from: this.userAddress })
		} catch(e: any) {
			console.log(e);
			let errorMsg;

			try {
				errorMsg = JSON.parse(e.message.slice(e.message.indexOf('\n'))).message;
			} catch(ignored) {
				errorMsg = e.message.split('\n')[0]
			}

			throw new Error(errorMsg)
		}

		return tx.send({ from: this.userAddress })
	}
	async harvest(tokenId: string, erc20TokenAddress: string) {

		const tx = this.wrapperContract.methods.harvest( tokenId, erc20TokenAddress );

		try {
			await tx.estimateGas({ from: this.userAddress })
		} catch(e: any) {
			console.log(e);
			let errorMsg;

			try {
				errorMsg = JSON.parse(e.message.slice(e.message.indexOf('\n'))).message;
			} catch(ignored) {
				errorMsg = e.message.split('\n')[0]
			}

			throw new Error(errorMsg)
		}

		return tx.send({ from: this.userAddress })
	}
	async unwrap(tokenId: string) {

		const tx = this.wrapperContract.methods.unWrap721( tokenId );

		try {
			await tx.estimateGas({ from: this.userAddress })
		} catch(e: any) {
			console.log(e);
			let errorMsg;

			try {
				errorMsg = JSON.parse(e.message.slice(e.message.indexOf('\n'))).message;
			} catch(ignored) {
				errorMsg = e.message.split('\n')[0]
			}

			throw new Error(errorMsg)
		}

		return tx.send({ from: this.userAddress })
	}

}