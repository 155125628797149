
import React  from 'react';
import Tippy  from '@tippyjs/react';
import Select, { components } from "react-select";
import {
	match,
	withRouter
} from 'react-router-dom';
import * as H from 'history';
import {
	ERC20ContractParamsType,
	MetamaskAdapter,
	WrappedTokenType
} from '../../models/BlockchainAdapter';
import {
	clearError,
	clearInfo,
	setAuthMethod,
	setError,
	setInfo,
} from '../../reducers';

import icon_select  from '../../static/pics/i-select.svg';
import icon_fox     from '../../static/pics/i-fox.svg';
import icon_connect from '../../static/pics/i-connect.svg';
import icon_loader  from '../../static/pics/loader.svg';
import default_icon from '../../static/pics/coins/_default.png';

import icon_error   from '../../static/pics/i-error.svg';

import banner_max from '../../static/pics/banner/staking-time.png';
import banner_mid from '../../static/pics/banner/staking-time-ipad.png';
import banner_min from '../../static/pics/banner/staking-time-mobile.png';

import {
	addThousandSeparator,
	monthesNames,
	tokenToFloat,
	tokenToInt,
	zeroedTime
} from '../../models/_utils';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type MainPageProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function,
	match                 : match;
	location              : H.Location,
	history               : H.History,
}
type MainPageState = {
	metamaskLogged     : boolean,
	input_nft_address  : string,
	input_token_id     : string,
	balanceNative      : BigNumber,
	decimalsNative     : number,
	symbolNative       : string,
	EIPPrefix          : string,
	iconNative         : string,
	secondsPerBlock    : number,

	erc20Tokens        : Array<ERC20ContractParamsType>,
	wrappedTokens      : Array<WrappedTokenType>,

	checkoutPrice      : string,
	checkoutERC20Token : ERC20ContractParamsType | undefined,
	checkoutPeriod     : { period: BigNumber, apy: BigNumber } | undefined,
	checkoutApproving  : boolean,
	checkoutProcessing : boolean,
	harvestProcessing  : Array<string>,
	unwrapProcessing   : Array<string>,

	explorerBaseUrl    : string,
	explorerName       : string,
	copiedHint         : undefined | { id: string, icon: string },
	wrongChain         : {
		chainId: number,
		chainName: string,
	} | undefined,
	urlNotFound        : boolean,
}

class MainPage extends React.Component<MainPageProps, MainPageState> {

	store                 : any;
	metamaskAdapter       : MetamaskAdapter;
	unsubscribe!          : Function;
	showAuthMethodSelector: Function;
	copiedHintTimer       : number;

	constructor(props: MainPageProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.copiedHintTimer        = 0;
		this.state = {
			metamaskLogged     : this.store.getState().metamaskAdapter.logged,
			input_nft_address  : '',
			input_token_id     : '',
			balanceNative      : this.store.getState().account.balanceNative,
			decimalsNative     : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative       : this.store.getState().metamaskAdapter.networkTokenTicket,
			EIPPrefix          : this.store.getState().metamaskAdapter.EIPPrefix,
			iconNative         : this.store.getState().metamaskAdapter.networkTokenIcon,
			secondsPerBlock    : this.store.getState().metamaskAdapter.secondsPerBlock,
			erc20Tokens        : this.store.getState().erc20Tokens,
			wrappedTokens      : this.store.getState().wrappedTokens,
			checkoutPeriod     : undefined,
			checkoutPrice      : '',
			checkoutERC20Token : this.store.getState().erc20Tokens.length ? this.store.getState().erc20Tokens[0] : undefined,
			checkoutApproving  : false,
			checkoutProcessing : false,
			harvestProcessing  : [],
			unwrapProcessing   : [],
			explorerBaseUrl    : this.store.getState().metamaskAdapter.explorerBaseUrl,
			explorerName       : this.store.getState().metamaskAdapter.explorerName,
			copiedHint         : undefined,
			wrongChain         : this.store.getState().metamaskAdapter.wrongChain,
			urlNotFound        : false,
		};
	}

	componentDidMount() {

		this.store.dispatch(clearError());

		this.unsubscribe = this.store.subscribe(() => {

			let checkoutERC20TokenUpdate = this.state.checkoutERC20Token;
			if ( this.state.checkoutERC20Token ) {
				const tokenFound = this.store.getState().erc20Tokens.filter((item: ERC20ContractParamsType) => { return item.address === this.state.checkoutERC20Token?.address });
				if ( tokenFound.length ) { checkoutERC20TokenUpdate = tokenFound[0] }
			} else {
				checkoutERC20TokenUpdate = this.store.getState().erc20Tokens.length ? this.store.getState().erc20Tokens[0] : undefined
			}
			let checkoutPeriodUpdate = this.state.checkoutPeriod;
			if ( !this.state.checkoutPeriod && checkoutERC20TokenUpdate && checkoutERC20TokenUpdate.periods ) {
				checkoutPeriodUpdate = checkoutERC20TokenUpdate.periods[0]
			}

			this.setState({
				balanceNative     : this.store.getState().account.balanceNative,
				metamaskLogged    : this.store.getState().metamaskAdapter.logged,
				decimalsNative    : this.store.getState().metamaskAdapter.networkTokenDecimals,
				symbolNative      : this.store.getState().metamaskAdapter.networkTokenTicket,
				EIPPrefix         : this.store.getState().metamaskAdapter.EIPPrefix,
				iconNative        : this.store.getState().metamaskAdapter.networkTokenIcon,
				secondsPerBlock   : this.store.getState().metamaskAdapter.secondsPerBlock,
				erc20Tokens       : this.store.getState().erc20Tokens,
				wrappedTokens     : this.store.getState().wrappedTokens,
				explorerBaseUrl   : this.store.getState().metamaskAdapter.explorerBaseUrl,
				explorerName      : this.store.getState().metamaskAdapter.explorerName,
				checkoutERC20Token: checkoutERC20TokenUpdate,
				checkoutPeriod    : checkoutPeriodUpdate,
				wrongChain        : this.store.getState().metamaskAdapter.wrongChain,
				urlNotFound       : this.store.getState().metamaskAdapter.urlNotFound,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	addedDate(date: BigNumber) {
		const output = new Date(date.toNumber());
		return `${output.getDate()} ${monthesNames[output.getMonth()]} ${output.getFullYear()}`;
	}

	getTokenLabelAndList() {
		const getTokenListMark = () => {
			if ( this.state.erc20Tokens.length > 1 ) {
				return (
					<svg className="arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 0.529297L5 4.76459L9 0.529297" stroke="white"></path>
					</svg>
				)
			}
		}
		const getTokensList = () => {
			if ( this.state.erc20Tokens.length > 1 ) {
				return (
					<ul className="select-coin__list">
						{
							this.state.erc20Tokens.map((item) => {
								return (
									<li
										key={ item.address }
										onClick={() => { this.setState({ checkoutERC20Token: item, checkoutPeriod: item.periods[0] }) }}
									><span className="field-unit"><img className="i-coin" src={ item.icon } alt="" />{ item.symbol }</span></li>
								)
							})
						}
					</ul>
				)
			}
		}

		if ( !this.state.checkoutERC20Token ) { return; }

		return (
			<div className="select-coin">
				<div className="select-coin__value">
					<span className="field-unit">
						<img className="i-coin" src={ this.state.checkoutERC20Token.icon } alt="" />
						{ this.state.checkoutERC20Token.symbol }
					</span>
					{ getTokenListMark() }
				</div>
				{ getTokensList() }
			</div>
		)
	}
	getPeriods() {
		const getSelectOptions = () => {
			if ( !this.state.checkoutERC20Token || !this.state.checkoutERC20Token.periods ) { return [{ label: '—', value: '—' }] }
			return this.state.checkoutERC20Token.periods.map((item) => { return { label: `<b>${getMonthes(item.period) } months</b> / ${getBlocks(item.period) } blocks`, value: item.period.toString() } })
		}
		const getSelectedOption = () => {
			if ( !this.state.checkoutERC20Token || !this.state.checkoutERC20Token.periods ) { return { label: '—', value: '—' } }
			if ( !this.state.checkoutPeriod ) { return { label: '—', value: '—' } }
			const foundPeriod = this.state.checkoutERC20Token.periods.filter((item) => { if ( !this.state.checkoutPeriod ) { return false; } return item.period.eq(this.state.checkoutPeriod.period) });

			if ( foundPeriod.length ) {
				return { label: `<b>${getMonthes(foundPeriod[0].period) } months</b> / ${getBlocks(foundPeriod[0].period) } blocks`, value: foundPeriod[0].period.toString() }
			} else {
				return { label: '—', value: '—' }
			}
		}
		const setSelectedOption = ( value: string ) => {
			if ( !this.state.checkoutERC20Token || !this.state.checkoutERC20Token.periods ) { this.setState({ checkoutPeriod: undefined }); return; }

			const foundPeriod = this.state.checkoutERC20Token.periods.filter((item) => { return item.period.toString() === value });
			if ( !foundPeriod.length ) { this.setState({ checkoutPeriod: undefined }); return; }

			this.setState({ checkoutPeriod: { period: foundPeriod[0].period, apy: foundPeriod[0].apy } });
		}
		const getMonthes = ( seconds: BigNumber ): string => {
			return seconds.dividedBy('1000').dividedBy('60').dividedBy('60').dividedBy('24').dividedBy('30').toFixed(0)
		}
		const getBlocks = ( seconds: BigNumber ): string => {
			return seconds.dividedBy('1000').dividedBy(this.state.secondsPerBlock).toFixed(0)
		}

		const DropdownIndicator = (props: any) => {
			return (
			  <components.DropdownIndicator {...props}>
				<img src={ icon_select } alt="" />
			  </components.DropdownIndicator>
			);
		  };

		return (
			<Select
				className="react-select-container rc-select"
				classNamePrefix="react-select"
				value={ getSelectedOption() }
				options={ getSelectOptions() }
				onChange={(e) => { if (!e) { return; } setSelectedOption(e.value); }}
				components={{ DropdownIndicator }}
				formatOptionLabel={(data) => {
					return (
						<span dangerouslySetInnerHTML={{ __html: data.label }} />
					);
				}}
			/>
		)
	}
	checkoutERC20Approve = (e: any) => {
		e.preventDefault();
		if ( !this.state.checkoutERC20Token ) { return; }

		this.setState({ checkoutApproving: true });
		const erc20Contract = this.metamaskAdapter.getERC20Contract(this.state.checkoutERC20Token.address);
		erc20Contract?.makeAllowance( tokenToInt(new BigNumber(this.state.checkoutPrice), this.state.checkoutERC20Token.decimals || 18) )
			.then(() => {
				erc20Contract.getBalance();
				this.setState({ checkoutApproving: false });
			})
			.catch((e: any) => {
				console.log(e);
				this.setState({ checkoutApproving: false });
				this.store.dispatch(setError({
					text: `Cannot make allowance: ${e.message.split('\n')[0]}`,
					buttons: undefined,
					links: undefined
				}));
			});
	}
	checkoutERC20Submit = (e: any) => {
		e.preventDefault();

		if ( !this.state.checkoutERC20Token ) { return; }
		if ( !this.state.checkoutPrice ) { return; }
		if ( !this.state.checkoutPeriod ) { return; }
		this.setState({ checkoutProcessing: true });

		const nowDate = new Date();
		nowDate.setUTCDate(nowDate.getDate() + 1);
		nowDate.setUTCHours(0, 0, 0, 0);
		const nowTime = nowDate.getTime();
		const date = new BigNumber(nowTime).plus(this.state.checkoutPeriod.period).dividedBy('1000');

		this.metamaskAdapter.wrappedTokenDispatcher.wrapForFarming(
			this.state.checkoutERC20Token.address,
			tokenToInt(new BigNumber(this.state.checkoutPrice), this.state.checkoutERC20Token.decimals || 18),
			date
		)
			.then((data) => {
				this.setState({ checkoutProcessing: false });
				this.metamaskAdapter.updateBalances();
				const contractAddress = this.metamaskAdapter.wrappedTokenDispatcher.wrapperContractAddress;
				const tokenId = data.events.Staked.returnValues.tokenId;
				const dateUnlock = new Date(date.multipliedBy('1000').toNumber());
				const dateStr = `${dateUnlock.getUTCDate()} ${monthesNames[dateUnlock.getUTCMonth()]} ${dateUnlock.getUTCFullYear()} ${zeroedTime(dateUnlock.getUTCHours())}:${zeroedTime(dateUnlock.getUTCMinutes())} (UTC)`
				this.store.dispatch(setInfo({
					text: `You have made a stake for wNFT (Contract address: ${contractAddress}, token ID: ${tokenId}, unlock date: ${dateStr})`,
				 	buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					 }],
					links: [{
						text: `View on ${this.state.explorerName}`,
						url: `${this.state.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
				this.setState({
					checkoutPrice: '',
					checkoutERC20Token: undefined,
				});
			})
			.catch((e) => {
				this.setState({ checkoutProcessing: false });
				this.store.dispatch(setError({
					text: e.message,
					buttons: undefined,
					links: undefined
				}));
			})
	}
	harvestSubmit = ( tokenId: string, erc20TokenAddress: string ) => {
		this.setState({ harvestProcessing: [
			...this.state.harvestProcessing,
			tokenId
		] });

		this.metamaskAdapter.wrappedTokenDispatcher.harvest(
			tokenId,
			erc20TokenAddress
		)
			.then((data) => {
				this.setState({ harvestProcessing: this.state.harvestProcessing.filter((item) => { return item !== tokenId }) });
				this.metamaskAdapter.updateBalances();

				const harvestedAddress = data.events.Harvest.returnValues.farmingToken;
				const foundCurrency = this.state.erc20Tokens.filter((item) => { return item.address === harvestedAddress });
				const amount = data.events.Harvest.returnValues.amount;
				let successMsg = '';
				if ( foundCurrency.length ) {
					const amountParsed = addThousandSeparator(tokenToFloat(new BigNumber(amount), foundCurrency[0].decimals || this.state.decimalsNative).toString());
					successMsg = `Your harvested ${ amountParsed } ${ foundCurrency[0].symbol } have been added to your principal amount of staking.`
				} else {
					const amountParsed = addThousandSeparator(tokenToFloat(new BigNumber(amount), this.state.decimalsNative).toString());
					successMsg = `Your harvested ${ amountParsed } of token ${ harvestedAddress } have been added to your principal amount of staking.`
				}

				this.store.dispatch(setInfo({
					text: successMsg,
				 	buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					 }],
					links: [{
						text: `View on ${this.state.explorerName}`,
						url: `${this.state.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
				this.setState({
					checkoutPrice: '',
					checkoutERC20Token: undefined,
				});
			})
			.catch((e) => {
				this.setState({ harvestProcessing: this.state.harvestProcessing.filter((item) => { return item !== tokenId }) });
				this.store.dispatch(setError({
					text: e.message,
					buttons: undefined,
					links: undefined
				}));
			})
	}
	unwrapSubmit = ( tokenId: string, amountStr: string ) => {
		this.setState({ unwrapProcessing: [
			...this.state.unwrapProcessing,
			tokenId
		] });

		this.metamaskAdapter.wrappedTokenDispatcher.unwrap( tokenId )
			.then((data) => {
				this.setState({ unwrapProcessing: this.state.unwrapProcessing.filter((item) => { return item !== tokenId }) });
				this.metamaskAdapter.updateBalances();
				this.store.dispatch(setInfo({
					text: `You have unwrapped wNFT and received ${amountStr}`,
				 	buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					 }],
					links: [{
						text: `View on ${this.state.explorerName}`,
						url: `${this.state.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
				this.setState({
					checkoutPrice: '',
					checkoutERC20Token: undefined,
				});
			})
			.catch((e) => {
				this.setState({ unwrapProcessing: this.state.unwrapProcessing.filter((item) => { return item !== tokenId }) });
				this.store.dispatch(setError({
					text: e.message,
					buttons: undefined,
					links: undefined
				}));
			})
	}
	getCheckoutBtn() {
		if ( !this.state.checkoutERC20Token || !this.state.checkoutPeriod || this.state.checkoutPrice === '' || new BigNumber(this.state.checkoutPrice).eq('0') ) { return ( <button className="btn btn-grad" disabled={ true }>Confirm staking</button> ) }
		if ( this.state.checkoutApproving ) {
			return (
				<button className="btn btn-yellow disabled-loading" disabled={ true }>
					<img className="loader" src={ icon_loader } alt="" />
				</button>
			)
		}
		if ( this.state.checkoutProcessing ) {
			return (
				<button className="btn btn-grad disabled-loading" disabled={ true }>
					<img className="loader" src={ icon_loader } alt="" />
				</button>
			)
		}

		if ( tokenToInt(new BigNumber(this.state.checkoutPrice), this.state.checkoutERC20Token.decimals || 18).gt(new BigNumber(this.state.checkoutERC20Token.balance)) ){
			return ( <button className="btn btn-grad" disabled={ true }>Not enough</button> )
		}
		if ( tokenToInt(new BigNumber(this.state.checkoutPrice), this.state.checkoutERC20Token.decimals || 18).gt(new BigNumber(this.state.checkoutERC20Token.allowance)) ){
			return ( <button
				className="btn btn-yellow"
				onClick={ this.checkoutERC20Approve }
			>Approve</button> )
		}
		return (
			<button
				className="btn btn-grad"
				onClick={ this.checkoutERC20Submit }
			>Confirm staking</button>
		)
	}
	getCheckoutBlock() {
		return (
			<div className="container">

			<div className="st-banner">
				<picture>
					<source media="(min-width: 1310px)" srcSet={ banner_max } />
					<source media="(min-width:  1024px)" srcSet={ banner_mid } />
					<img src={ banner_min } alt="Staking time" />
				</picture>
			</div>

			<div className="row">
			<div className="col-12 col-lg-10">
			<div className="st-wrap">
				<div className="st-wrap__header">
					<div className="h3">Stake { this.state.checkoutERC20Token?.symbol } </div>
					<div className="st-apr">APY<span>{ this.state.checkoutPeriod ? this.state.checkoutPeriod.apy.toString() : '—' }%</span></div>
				</div>
				<div className="st-wrap__form">
					<form>
						<div className="row">
							<div className="col col-12 col-md-5 col-lg-5">
								<label className="input-label">Amount</label>
								<div className="input-group">
									<input
										className="input-control"
										type="text"
										placeholder="0.000"
										value={ addThousandSeparator(this.state.checkoutPrice) }
										onChange={(e) => {
											let value = e.target.value.replaceAll(',', '.').replaceAll(' ', '');
											if ( value.split('.')[1] && value.split('.')[1].length > this.state.decimalsNative ) { return; }
											if ( value.startsWith('00') ) { return; }
											if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
												if ( new BigNumber(value).isNaN() ) { return; }
												value = new BigNumber(value).toString();
											}
											this.setState({ checkoutPrice: value });
										}}
									/>
									{ this.getTokenLabelAndList() }
								</div>
								<div className="c-add__max">
									<div>
										<span>Max: </span>
										<button
											onClick={(e) => {
												e.preventDefault();
												if ( !this.state.checkoutERC20Token || this.state.checkoutERC20Token.balance.eq(0) ) { return; }
												this.setState({ checkoutPrice: tokenToFloat(this.state.checkoutERC20Token.balance, this.state.checkoutERC20Token.decimals || 18).toString() })
											}}
										>{ this.state.checkoutERC20Token ? addThousandSeparator(tokenToFloat(this.state.checkoutERC20Token.balance, this.state.checkoutERC20Token.decimals || 18).toFixed(3)) : '—' }</button>
									</div>
									<div>
										<span>Allowance: </span>
										<button
											onClick={(e) => {
												e.preventDefault();
												if ( !this.state.checkoutERC20Token || this.state.checkoutERC20Token.allowance.eq(0) ) { return; }
												this.setState({ checkoutPrice: tokenToFloat(this.state.checkoutERC20Token.allowance, this.state.checkoutERC20Token.decimals || 18).toString() })
											}}
										>{ this.state.checkoutERC20Token ? addThousandSeparator(tokenToFloat(this.state.checkoutERC20Token.allowance, this.state.checkoutERC20Token.decimals || 18).toFixed(3)) : '—' }</button>
									</div>
								</div>
							</div>
							<div className="col col-12 col-md-4 col-lg-4">
								<label className="input-label">Staking period</label>
								{ this.getPeriods() }
							</div>
							<div className="col col-12 col-md-3 col-lg-3">
								<label className="input-label">&nbsp;</label>
								{ this.getCheckoutBtn() }
							</div>
						</div>
						{
							this.state.checkoutERC20Token &&
							tokenToInt(new BigNumber(this.state.checkoutPrice), this.state.checkoutERC20Token.decimals || 18).gt(new BigNumber(this.state.checkoutERC20Token.allowance)) &&
							!tokenToInt(new BigNumber(this.state.checkoutPrice), this.state.checkoutERC20Token.decimals || 18).gt(new BigNumber(this.state.checkoutERC20Token.balance))
								? <div className="info">Please give permission smart contract to stake your { this.state.EIPPrefix }20 tokens.</div>
								: ''
						}
					</form>
				</div>
			</div>
			</div>
			</div>
			</div>
		)
	}
	getUnwrapDateBlocks( date: BigNumber | undefined ) {
		if ( date === undefined || date.eq(0) ) { return '—' }
		const now = new Date();
		if ( now.getTime() > date.toNumber() ) {
			return '—'
		} else {
			const diff = date.toNumber() - now.getTime();
			return new BigNumber(diff).dividedBy(1000).dividedBy(this.state.secondsPerBlock).toFixed(0);
		}
	}
	getUnwrapDatePretty( date: BigNumber | undefined ) {
		if ( date === undefined || date.eq(0) ) { return '—' }

		const now = new Date();
		if ( now.getTime() > date.toNumber() ) {
			return 'Available now'
		} else {
			const dateParsed = new Date(Number(date));
			return `${dateParsed.getDate()} ${monthesNames[dateParsed.getMonth()]} ${dateParsed.getFullYear()}`
		}
	}
	isWithdrawDisabled(token: WrappedTokenType) {
		const nowDate = new Date();
		const nowTime = nowDate.getTime();
		const now = new BigNumber( nowTime );
		return token.unwrapAfter && token.unwrapAfter.gt(now)
	}
	getHarvestBtn(token: WrappedTokenType, tokenAddress: string, reward: BigNumber) {
		if ( this.state.harvestProcessing.includes( token.tokenId ) ) {
			return (
				<button className="btn btn-yellow disabled-loading" disabled={ true }>
					<img className="loader" src={ icon_loader } alt="" />
				</button>
			)
		} else {
			return (
				<button
					className="btn btn-yellow"
					disabled={ !reward || reward.eq(0) }
					onClick={(e: any) => { e.preventDefault(); this.harvestSubmit( token.tokenId, tokenAddress ) }}
				>Harvest and ReStake</button>
			)
		}
	}
	getWithdrawBtn(token: WrappedTokenType) {
		if ( this.state.unwrapProcessing.includes( token.tokenId ) ) {
			return (
				<button className="btn btn-grad disabled-loading" disabled={ true }>
					<img className="loader" src={ icon_loader } alt="" />
				</button>
			)
		} else {
			return (
				<button
					className="btn btn-grad"
					disabled={ this.isWithdrawDisabled( token ) }
					onClick={(e: any) => {
						e.preventDefault();
						let amountStr = '';
						const foundCurrency = this.state.erc20Tokens.filter((item) => { return item.address === token.backedERC20Value[0].address });
						if ( foundCurrency.length ) {
							const amountParsed = addThousandSeparator(tokenToFloat(token.backedERC20Value[0].amount, foundCurrency[0].decimals || this.state.decimalsNative).toString());
							amountStr = `${ amountParsed } ${ foundCurrency[0].symbol }`
						} else {
							const amountParsed = addThousandSeparator(tokenToFloat(token.backedERC20Value[0].amount, this.state.decimalsNative).toString());
							amountStr = `${ amountParsed } of token ${ token.backedERC20Value[0].address }`
						}
						this.unwrapSubmit( token.tokenId, amountStr )
					}}
				>Withdraw</button>
			)
		}
	}
	getTokenBlock(token: WrappedTokenType) {

		const foundToken = token.backedERC20Value.filter((item) => { return item.address === this.state.checkoutERC20Token?.address });
		const tokenToShow = foundToken.length ? foundToken[0] : token.backedERC20Value[0];
		const foundCurrency = this.state.erc20Tokens.filter((item) => { return item.address === tokenToShow.address });
		const currencyToShow = foundCurrency.length ? foundCurrency[0] : { address: tokenToShow.address, symbol: tokenToShow.address, icon: default_icon, decimals: 18 }

		return (
			<div className="c-col" key={ token.tokenId }>
				<div className="st-card">
					<div className="st-card__row">
						<div className="st-sum">{ addThousandSeparator(tokenToFloat(tokenToShow.amount, currencyToShow.decimals || 18).toString()) }</div>
						<div className="st-unit">
							<div className="field-unit">
								<img className="i-coin" src={ currencyToShow.icon } alt="" />
								{ currencyToShow.symbol }
							</div>
						</div>
					</div>
					<div className="st-card__row">
						<div className="field-wrap">
							<div className="field-row">
								<label className="field-label">Unstake date</label>
							</div>
							<div className="field-row">
								<div className="field-control">{ this.getUnwrapDatePretty(token.unwrapAfter) }</div>
								<div className="field-unit">{ this.getUnwrapDateBlocks(token.unwrapAfter) } blocks</div>
							</div>
						</div>
					</div>
					<div className="st-card__row">
						<div className="field-wrap">
							<div className="field-row">
								<label className="field-label">
									Staking APY
									<Tippy
										content={'The amount of the reward percentage for staking'}
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 260 }
									>
										<span className="i-tip black"></span>
									</Tippy>
								</label>
							</div>
							<div className="field-row">
								<div className="field-control">{ tokenToShow.apy.toString() }%</div>
							</div>
						</div>
					</div>
					<div className="st-card__row">
						<div className="field-wrap">
							<div className="field-row">
								<label className="field-label">Reward</label>
							</div>
							<div className="field-row">
								<div className="field-control">{ addThousandSeparator(tokenToFloat(tokenToShow.reward, currencyToShow.decimals || 18).toString()) }</div>
								<div className="field-unit">
									<img className="i-coin" src={ currencyToShow.icon } alt="" />
									{ currencyToShow.symbol }
								</div>
							</div>
						</div>
					</div>
					<div className="st-card__btns">
						{ this.getHarvestBtn(token, tokenToShow.address, tokenToShow.reward) }
						{ this.getWithdrawBtn(token) }
					</div>
				</div>
			</div>
		)
	}
	getTokenList() {
		return (
			<div className="c-row">
				{ this.state.wrappedTokens
					.sort((item, prev) => { return new BigNumber(item.tokenId).lt(new BigNumber(prev.tokenId)) ? 0 : -1 })
					.map((item) => { return this.getTokenBlock(item) }) }
			</div>
		)
	}

	render() {

		if ( !this.state.metamaskLogged ) {
			return (
				<React.Fragment>
				<main className="s-main"></main>
				<div className="modal">
					<div className="modal__inner">
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
						<div className="modal__header">
							<div className="h2">{ 'Choose your wallet' }</div>
						</div>
						<div className="c-connect">
							<div className="modal__btns">
							<div className="col">
								<button
									className="btn-wallet"
									onClick={async () => {
										this.store.dispatch(setAuthMethod('METAMASK'));
										await this.metamaskAdapter.connect();
									}}
								>
									<span className="img"><img src={ icon_fox } alt="" /></span><span>Metamask</span>
								</button>
							</div>
							<div className="col">
								<button
									className="btn-wallet"
									onClick={async () => {
										this.store.dispatch(setAuthMethod('WALLET_CONNECT'));
										await this.metamaskAdapter.connect();
									}}
								>
									<span className="img"><img src={ icon_connect } alt="" /></span><span>Walletconnect</span>
								</button>
							</div>
							</div>
							<div
								className="modal__disclaimer"
								style={{
									fontSize: '0.8em',
									lineHeight: '1.4em',
									marginTop: '32px',
									color: 'rgba(#fff, 0.7)',
								}}
							>
								<p
									style={{ marginBottom: 0 }}
								>Don't forget that buying utility tokens puts <a href="https://docs.envelop.is/key-documents/white-paper/disclaimers" target="_blank" rel="noopener noreferrer">all&nbsp;the&nbsp;responsibility</a> on&nbsp;you alone. By&nbsp;purchasing wNFT, you accept the real disclaimers and all the provisions that go&nbsp;with them.</p>
							</div>
						</div>
						</div>
					</div>
					</div>
				</div>
				</React.Fragment>
			)
		}

		if ( this.state.wrongChain ) {
			return (
				<div className="modal">
				<div className="modal__inner">
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
							<div className="c-success">
								<img className="c-success__img" src={ icon_error } alt="" />
								<div className="h2">Error Screen</div>
								<p>You are trying to open chain which does not match one selected in metamask</p>
								<div className="modal__btns">
									<div className="col-12 col-sm-auto mb-3 mb-md-0">
										<button
											className="btn btn-grad"
											onClick={() => {
												window.location.href = `/${window.location.pathname.replaceAll('/', '')}/#/`;
												window.location.reload();
											}}
										>Continue with current</button>
									</div>
									<div className="col-12 col-sm-auto mb-3 mb-md-0">
										<button
											className="btn"
											onClick={() => {
												(window as any).ethereum.request({
													method: 'wallet_switchEthereumChain',
													params: [{ chainId: '0x' + Number(this.state.wrongChain?.chainId).toString(16) }], // chainId must be in hexadecimal numbers
												})
											}}
										>{ `Switch network to ${this.state.wrongChain?.chainName}` }</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			)
		}

		if ( this.state.urlNotFound ) {
			return (
				<main className="s-main">
					<div className="sec-error">
						<div className="sec-error__text">
							<h1>ERROR 404 </h1>
							<div className="h1_sub text-grad">PAGE NOT FOUND</div>
							<p>We couldn't find the page you're looking for.</p>
							<p className="actions">
								<a
									href="/#/"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										window.location.href = `/#/`;
										window.location.reload();
									}}
								>Return Home</a>
							</p>
						</div>
					</div>
				</main>
			)
		}

		return (
			<main className="s-main">
				{ this.getCheckoutBlock() }
				<div className="divider left short d-none d-md-block"></div>
				<div className="divider right short-20 d-md-none"> </div>
				<div className="container">
					<div className="st-header">
						<div className="h3">Current stakes</div>
					</div>
					{ this.getTokenList() }
				</div>
			</main>
		)
	}
}

export default withRouter(MainPage);