
import config                from '../../config.json';
import Web3                  from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import  {
	ERC20Contract,
	WrappedTokenDispatcher
} from '.';
import {
	setError,
	resetAppData,

	metamaskConnectionSuccess,
	metamaskConnectionRejected,
	metamaskConnectionNotInstalled,
	metamaskSetChainParams,
	metamaskSetAvailableChains,
	setAuthMethod,
	updateNativeBalance,
	setLoading,
	clearError,
	unsetLoading,
	setWrongChain,
} from '../../reducers';

import default_icon from '../../static/pics/coins/_default.png';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type MetamaskAdapterPropsType = {
	store: any,
}
export type ChainParamsType = {
	chainId?              : number | undefined,
	chainName             : string,
	chainRPCUrl           : string,
	networkTokenTicket    : string,
	EIPPrefix             : string,
	networkTokenDecimals  : number | undefined,
	networkTokenIcon      : string | undefined,
	wrapperContract       : string;
	wrapperContractAux    : Array<{ url: string, address: string }>;
	supportedERC20Tokens  : Array<string>,
	isTestNetwork         : Boolean;
	explorerBaseUrl       : string;
	explorerName          : string;
	secondsPerBlock?      : number;
};

export default class MetamaskAdapter {

	store                  : any;
	web3!                  : Web3;
	wcProvider!            : any;
	availiableChains       : Array<ChainParamsType>;
	chainId?               : number;
	chainConfig!           : ChainParamsType;
	userAddress!           : string;
	erc20CollateralTokens  : Array<ERC20Contract>;
	wrappedTokenDispatcher!: WrappedTokenDispatcher;

	accessAtempts   : number;

	constructor(props: MetamaskAdapterPropsType) {
		this.store = props.store;
		this.availiableChains = config.CHAIN_SPECIFIC_DATA;
		this.store.dispatch(metamaskSetAvailableChains(this.availiableChains));
		this.erc20CollateralTokens = [];

		this.accessAtempts = 0;
	}
	async connect() {
		const method = this.store.getState().metamaskAdapter.authMethod;

		this.store.dispatch(setLoading({ msg: 'Waiting for metamask login' }));
		if ( method === 'METAMASK' ) {
			try {
				if ( !(window as any).ethereum ) {
					// console.log((window as any).ethereum)
					this.store.dispatch(metamaskConnectionNotInstalled());
					this.store.dispatch(setError({
						text: 'No access to metamask',
						buttons: [
							{
								text: 'Download extension or mobile app',
								clickFunc: () => { window.open('https://metamask.io/download.html', "_blank"); }
							},
							{
								text: 'Close',
								clickFunc: () => { this.store.dispatch(clearError()) }
							}
						],
						links: undefined
					}));
					return;
				} else {
					await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
				}
			} catch(e) {
				this.accessAtempts++;
				console.log('Cannot connect to metamask:', e);

				if ( this.accessAtempts < 3 ) {
					setTimeout(() => { this.connect() }, 100);
				} else {
					this.accessAtempts = 0;
					this.store.dispatch(setError({
						text: 'You should grant access in metamask',
						buttons: [{
							text: 'Try again',
							clickFunc: () => { this.connect() }
						}],
						links: undefined
					}));
					this.store.dispatch(metamaskConnectionRejected());
					localStorage.removeItem('provider_type');
				}

				return;
			}

			try {
				this.web3 = new Web3( (window as any).ethereum );
				localStorage.setItem('provider_type', 'METAMASK');
			} catch(e: any) {
				this.store.dispatch(setError({
					text: 'Cannot connect to metamask',
					buttons: [{
						text: 'Try again',
						clickFunc: () => { this.connect() }
					}],
					links: undefined
				}));
				console.log(`Cannot connect to metamask: ${e.toString()}`);
				this.store.dispatch(metamaskConnectionRejected());
				localStorage.removeItem('provider_type');
			}

		}
		if ( method === 'WALLET_CONNECT' ) {
			const urls: any = {};
			this.availiableChains.forEach((item) => {
				if (!item.chainId) { return; }
				urls[item.chainId] = item.chainRPCUrl;
			});

			this.wcProvider = new WalletConnectProvider({
				rpc: urls,
			});
			await this.wcProvider.enable();
			try {
				this.web3 = new Web3( this.wcProvider );
				localStorage.setItem('provider_type', 'WALLET_CONNECT');
			} catch(e: any) {
				this.store.dispatch(setError({
					text: 'Cannot connect to walletconnect',
					buttons: [{
						text: 'Try again',
						clickFunc: () => { this.connect() }
					}],
					links: undefined
				}));
				localStorage.removeItem('provider_type');
				console.log(`Cannot connect to metamask: ${e.toString()}`);
				this.store.dispatch(metamaskConnectionRejected());
			}

		}

		// console.log('web3', this.web3);
		const accounts = await this.web3.eth.getAccounts();
		this.userAddress = accounts[0];
		this.store.dispatch(metamaskConnectionSuccess({
			address: this.userAddress,
		}));
		await this.getChainId();
		this.fetchNativeBalance();
		this.updateChainListener(method);
	}
	async getChainId() {
		const chainId = await this.web3.eth.getChainId()
		this.chainId = chainId;
		await this.getChainConfg();
	}
	async getChainBlockInterval() {
		const BLOCKS_TO_COUNT = 10;
		const lastBlockNumber = await this.web3.eth.getBlockNumber();
		let lastTimestamp = 0;
		let sumDiffs = 0;

		for ( let item = 0; item < BLOCKS_TO_COUNT; item++ ) {
			const currBlock = await this.web3.eth.getBlock(lastBlockNumber - item);
			if (lastTimestamp !== 0) {
				sumDiffs = sumDiffs + (lastTimestamp - Number(currBlock.timestamp));
			}
			lastTimestamp = Number(currBlock.timestamp);
		}

		return sumDiffs / BLOCKS_TO_COUNT;
	}
	async getChainConfg() {

		const url = window.location.hash.toLowerCase().replaceAll('#', '').replaceAll('/', '');
		const foundUrlInChain = this.availiableChains.filter(
			(item) => {
				return item.wrapperContractAux.filter((iitem) => { return iitem.url.toLowerCase() === url }).length
			}
		);
		if ( foundUrlInChain.length ) {
			if ( foundUrlInChain[0].chainId !== this.chainId ) {
				this.store.dispatch(setWrongChain({
					chainName: foundUrlInChain[0].chainName,
					chainId  : foundUrlInChain[0].chainId || 0,
				}));
				return;
			}
		}

		this.store.dispatch(setLoading({ msg: 'Connecting to contract' }));
		let foundChain = this.availiableChains.filter((item: ChainParamsType) => { return item.chainId === this.chainId });
		if ( !foundChain.length ) {
			const chosenAuthMethod = this.store.getState().metamaskAdapter.authMethod;
			this.store.dispatch(resetAppData());
			this.store.dispatch(setAuthMethod(chosenAuthMethod));
			localStorage.removeItem('walletconnect');
			const availableChainsStr = this.availiableChains.map((item) => { return item.isTestNetwork ? `${item.chainName} (testnet)` : item.chainName }).join(', ');
			this.store.dispatch(setError({
				text: `Unsupported chain. Please choose from: ${ availableChainsStr }`,
				buttons: [{
					text: 'Connect again',
					clickFunc: () => { this.connect() }
				}],
				links: undefined
			}));
			console.log('Cannot load domain info');
			return;
		}

		let icon = default_icon;
		try { icon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.jpeg`).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.jpg` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.png` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.svg` ).default } catch (ignored) {}

		const secondsPerBlock = await this.getChainBlockInterval();

		this.store.dispatch(metamaskSetChainParams({
			chainId             : this.chainId,
			chainName           : foundChain[0].chainName,
			chainRPCUrl         : foundChain[0].chainRPCUrl,
			networkTokenTicket  : foundChain[0].networkTokenTicket,
			EIPPrefix           : foundChain[0].EIPPrefix,
			networkTokenDecimals: foundChain[0].networkTokenDecimals,
			networkTokenIcon    : icon,
			isTestNetwork       : foundChain[0].isTestNetwork,
			explorerBaseUrl     : foundChain[0].explorerBaseUrl,
			explorerName        : foundChain[0].explorerName,
			secondsPerBlock     : secondsPerBlock,
		}));

		this.chainConfig = {
			chainId               : this.chainId,
			chainName             : foundChain[0].chainName,
			chainRPCUrl           : foundChain[0].chainRPCUrl,
			networkTokenTicket    : foundChain[0].networkTokenTicket,
			EIPPrefix             : foundChain[0].EIPPrefix,
			networkTokenDecimals  : foundChain[0].networkTokenDecimals,
			networkTokenIcon      : icon,
			wrapperContract       : foundChain[0].wrapperContract,
			wrapperContractAux    : foundChain[0].wrapperContractAux,
			supportedERC20Tokens  : foundChain[0].supportedERC20Tokens,
			isTestNetwork         : foundChain[0].isTestNetwork,
			explorerBaseUrl       : foundChain[0].explorerBaseUrl,
			explorerName          : foundChain[0].explorerName,
			secondsPerBlock       : secondsPerBlock,
		}
		await this.createWrappedTokenDispatcher();
		await this.createERC20Contracts();
		this.store.dispatch(unsetLoading());

	}
	updateChainListener(authMethod: string) {
		if ( authMethod === 'METAMASK' ) {
			(window as any).ethereum.on('chainChanged', () => {
				window.location.reload();
			});
			(window as any).ethereum.on('accountsChanged', () => {
				window.location.reload();
			});
		}
		if ( authMethod === 'WALLET_CONNECT' ) {
			this.wcProvider.on("accountsChanged", (accounts: string[]) => {
				window.location.reload();
			});
			this.wcProvider.on("chainChanged", (chainId: number) => {
				window.location.reload();
			});
		}
	}

	async createERC20Contracts() {
		this.erc20CollateralTokens = await Promise.all(this.chainConfig.supportedERC20Tokens.map(async (item) => {
			const contract = new ERC20Contract({
				web3            : this.web3,
				store           : this.store,
				contractAddress : item,
				userAddress     : this.userAddress,
				erc20Type       : '',
				allowanceAddress: this.chainConfig.wrapperContract,
				wrapperContract : this.wrappedTokenDispatcher,
				metamaskAdapter : this,
			});
			await contract.getParams()
			return contract;
		}));
	}
	getERC20Contract(address: string): ERC20Contract | undefined {
		const wrapperERC20 = this.wrappedTokenDispatcher.tradableERC20TokenContract;
		if ( wrapperERC20 && wrapperERC20.contractAddress === address ) { return wrapperERC20; }

		const foundContract = this.erc20CollateralTokens.filter((item: ERC20Contract) => { return item.contractAddress === address });
		if ( foundContract.length ) {
			return foundContract[0]
		} else {
			return undefined;
		}
	}
	async createWrappedTokenDispatcher() {
		try {
			this.wrappedTokenDispatcher = new WrappedTokenDispatcher({
				store                 : this.store,
				web3                  : this.web3,
				userAddress           : this.userAddress,
				wrapperContractAddress: this.chainConfig.wrapperContract,
				wrapperContractAux    : this.chainConfig.wrapperContractAux,
				erc20Tokens           : this.erc20CollateralTokens.map((item) => { return item.erc20Params }),
				metamaskAdapter       : this,
			});
			await this.wrappedTokenDispatcher.createERC20Contract();
		} catch (e: any) {
			this.store.dispatch(setError({
				text: e.message,
				buttons: [{
					text: 'Ok',
					clickFunc: () => {
						window.location.href = `${process.env.PUBLIC_URL}/#/`;
						window.location.reload();
					}
				}],
				links: undefined
			}))
		}
	}

	async updateBalances() {
		this.fetchNativeBalance();
		this.wrappedTokenDispatcher.getNFTTokens();
		if ( this.wrappedTokenDispatcher.tradableERC20TokenContract ) {
			this.wrappedTokenDispatcher.tradableERC20TokenContract.getBalance();
		}
		this.erc20CollateralTokens.forEach((item) => { item.getBalance(); });
	}
	async fetchNativeBalance() {
		const balance = new BigNumber(await this.web3.eth.getBalance(this.userAddress));
		this.store.dispatch(updateNativeBalance({ balance }));
	}

}