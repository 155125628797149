
import React                   from 'react';
import { createStore }         from 'redux';
import { MetamaskAdapter }     from '../../models/BlockchainAdapter';
import {
	clearError,
	clearInfo,
	reducer,
	setAuthMethod,
} from '../../reducers';
import {
	HashRouter as Router,
	Route,
	Switch,
} from "react-router-dom";

import Header     from '../Header';
import Footer     from '../Footer';
import MainPage   from '../MainPage';

import icon_error   from '../../static/pics/shark-pic.png';
import icon_fox     from '../../static/pics/i-fox.svg';
import icon_connect from '../../static/pics/i-connect.svg';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type AppParamsType = {}
type AppState = {
	loading    : string,
	error      : undefined | {
		text: string,
		buttons: undefined | Array<{
			text: string,
			clickFunc: Function,
		}>,
		links: undefined | Array<{
			text: string,
			url : string,
		}>,
	},
	info: undefined | {
		text: string,
		buttons: undefined | Array<{
			text: string,
			clickFunc: Function,
		}>,
		links: undefined | Array<{
			text: string,
			url : string,
		}>,
	},
	authMethodSelector  : boolean,
	logged              : boolean,
	metamaskNotInstalled: boolean,
	permissionRejected  : boolean,
	chainId             : number,
	balanceNative       : BigNumber,
}

class App extends React.Component<AppParamsType> {
	store          : any;
	metamaskAdapter: MetamaskAdapter;
	unsubscribe!   : Function;
	state          : AppState;

	constructor(props: AppParamsType) {
		super(props);

		this.state = {
			loading             : '',
			error               : undefined,
			info                : undefined,
			authMethodSelector  : false,
			logged              : false,
			metamaskNotInstalled: false,
			permissionRejected  : false,
			chainId             : 0,
			balanceNative       : new BigNumber(0),
		};

		this.store = createStore(reducer,(window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());
		this.metamaskAdapter = new MetamaskAdapter({ store: this.store });
	}

	async componentDidMount() {

		this.unsubscribe = this.store.subscribe(() => {

			this.setState({
				loading             : this.store.getState()._loading,
				error               : this.store.getState()._error,
				info                : this.store.getState()._info,
				logged              : this.store.getState().metamaskAdapter.logged,
				metamaskNotInstalled: this.store.getState().metamaskAdapter.metamaskNotInstalled,
				permissionRejected  : this.store.getState().metamaskAdapter.permissionRejected,
				chainId             : this.store.getState().metamaskAdapter.chainId,

				balanceNative       : this.store.getState().account.balanceNative,
			});
        });

		const prevAuthMethod = localStorage.getItem('provider_type');
		if ( prevAuthMethod ) {
			this.store.dispatch(setAuthMethod( prevAuthMethod ));
			await this.metamaskAdapter.connect();
		}
 	}
 	componentWillUnmount() { this.unsubscribe(); }

	getOverlay() {
		const getErrorBtns = (msg: string) => {
			if (msg === 'error') {
				if (this.state.error?.buttons) {
					return this.state.error.buttons.map((item) => {
						return (
							<React.Fragment key={ item.text }>
								<div className="col-12 col-sm-auto">
									<button
										className="btn btn-grad"
										onClick={() => {
											item.clickFunc();
										}}
									>{ item.text }</button>
								</div>
								<br />
							</React.Fragment>
						)
					})
				} else {
					return (
						<div className="col-12 col-sm-auto" key={ '_default' }>
							<button
								className="btn btn-grad"
								onClick={() => { this.store.dispatch(clearError()) }}
							>{ 'Accept this fact' }</button>
						</div>
					)
				}
			}
			if (msg === 'info') {
				if (this.state.info?.buttons) {
					return this.state.info.buttons.map((item) => {
						return (
							<React.Fragment key={ item.text }>
								<div className="col-12 col-sm-auto">
									<button
										className="btn btn-grad"
										onClick={() => {
											item.clickFunc();
										}}
									>{ item.text }</button>
								</div>
								<br />
							</React.Fragment>
						)
					})
				} else {
					return (
						<div className="col-12 col-sm-auto" key={ '_default' }>
							<button
								className="btn btn-grad"
								onClick={() => { this.store.dispatch(clearInfo()) }}
							>{ 'Accept this fact' }</button>
						</div>
					)
				}
			}
		}
		const getLinks = (msg: string) => {
			if ( msg === 'error' ) {
				if (this.state.error?.links) {
					return this.state.error.links.map((item) => {
						return (
							<div className="col-12 col-sm-auto" key={ item.url }>
								<a
									className="btn-link"
									target="_blank" rel="noopener noreferrer"
									href={ item.url }
								>{ item.text }</a>
							</div>
						)
					})
				}
			}
			if ( msg === 'info' ) {
				if (this.state.info?.links) {
					return this.state.info.links.map((item) => {
						return (
							<div className="col-12 col-sm-auto" key={ item.url }>
								<a
									className="btn-link"
									target="_blank" rel="noopener noreferrer"
									href={ item.url }
								>{ item.text }</a>
							</div>
						)
					})
				}
			}
		}

		if ( this.state.error ) {
			return (
				<div className="modal">
				<div className="modal__inner">
				<div className="modal__bg"></div>
				<div className="container">
				<div className="modal__content">
					<div className="c-success">
						<img className="c-success__img" src={ icon_error } alt ="" />
						<div className="h2">{ 'Error Screen' }</div>
						<p>{ this.state.error.text }</p>
						<div className="modal__btns">
							{ getErrorBtns('error') }
							{ getLinks('error') }
						</div>
					</div>
				</div>
				</div>
				</div>
				</div>
			)
		}

		if ( this.state.loading ) {
			return (
				<div className="modal">
				<div className="modal__inner">
				<div className="modal__bg"></div>
				<div className="container">
				<div className="modal__content">
					<div className="c-success">
						<img className="c-success__img" src={ icon_error } alt ="" />
						<div className="h2">
							{ 'Loading' }
							<span className="loading-dots"><span>.</span><span>.</span><span>.</span></span>
						</div>
						<p>{ this.state.loading }</p>
					</div>
				</div>
				</div>
				</div>
				</div>
			)
		}
		if ( this.state.info ) {
			return (
				<div className="modal">
				<div className="modal__inner">
				<div className="modal__bg"></div>
				<div className="container">
				<div className="modal__content">
					<div className="c-success">
						<img className="c-success__img" src={ '' } alt ="" />
						<div className="h2">{ 'Success' }</div>
						<p>{ this.state.info.text }</p>
						<div className="col-12 col-sm-auto" key={ '_default' }>
						<div className="modal__btns">
							{ getErrorBtns('info') }
							{ getLinks('info') }
						</div>
					</div>
					</div>
				</div>
				</div>
				</div>
				</div>
			)
		}
		return ''
	}

	getAuthMethodSelector() {
		if ( this.state.authMethodSelector ) {
			return (
				<div className="modal">
					<div className="modal__inner">
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
						<div
							className="modal__close"
							onClick={() => {
								this.setState({ authMethodSelector: false });
							}}
						>
							<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M-7.64949e-07 17.5C-3.42479e-07 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83501 27.165 -1.18742e-06 17.5 -7.64949e-07C7.83502 -3.42479e-07 -1.18742e-06 7.83502 -7.64949e-07 17.5ZM10.4167 22.1302C9.82084 22.7261 9.82084 23.6921 10.4167 24.2879C11.0125 24.8838 11.9785 24.8838 12.5744 24.2879L17.2493 19.6129L21.9243 24.2879C22.5202 24.8838 23.4862 24.8838 24.082 24.2879C24.6779 23.6921 24.6779 22.7261 24.082 22.1302L19.407 17.4553L24.082 12.7803C24.6779 12.1844 24.6779 11.2184 24.082 10.6226C23.4862 10.0267 22.5202 10.0267 21.9243 10.6226L17.2493 15.2976L12.5744 10.6226C11.9785 10.0267 11.0125 10.0267 10.4167 10.6226C9.82084 11.2184 9.82084 12.1844 10.4167 12.7803L15.0917 17.4553L10.4167 22.1302Z" fill="#1D1D1D"></path>
							</svg>
						</div>
						<div className="modal__header">
							<div className="h2">{ 'Choose your wallet' }</div>
						</div>
						<div className="c-connect">
							<div className="modal__btns">
							<div className="col">
								<button
									className="btn-wallet"
									onClick={async () => {
										this.store.dispatch(setAuthMethod('METAMASK'));
										await this.metamaskAdapter.connect();
										this.setState({ authMethodSelector: false });
									}}
								>
									<span className="img"><img src={ icon_fox } alt="" /></span><span>Metamask</span>
								</button>
							</div>
							<div className="col">
								<button
									className="btn-wallet"
									onClick={async () => {
										this.store.dispatch(setAuthMethod('WALLET_CONNECT'));
										await this.metamaskAdapter.connect();
										this.setState({ authMethodSelector: false });
									}}
								>
									<span className="img"><img src={ icon_connect } alt="" /></span><span>Walletconnect</span>
								</button>
							</div>
							</div>
							<div className="modal__disclaimer">
								<p>Don't forget that buying utility tokens puts <a href="https://docs.envelop.is/key-documents/white-paper/disclaimers" target="_blank" rel="noopener noreferrer">all&nbsp;the&nbsp;responsibility</a> on&nbsp;you alone. By&nbsp;purchasing wNFT, you accept the real disclaimers and all the provisions that go&nbsp;with them.</p>
							</div>
						</div>
						</div>
					</div>
					</div>
				</div>
			)
		} else {
			return ''
		}
	}

	render() {

		return (
			<Router>
				<Header
					store           = { this.store }
					metamaskAdapter = { this.metamaskAdapter }
					showAuthMethodSelector = {() => { this.setState({ authMethodSelector: true }) }}
				/>

				<Switch>
					<Route path="/">
						<MainPage
							store = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {() => { this.setState({ authMethodSelector: true }) }}
						/>
					</Route>
				</Switch>

				{ this.getOverlay() }
				{ this.getAuthMethodSelector() }

				<Footer />
			</Router>
		)
	}
}

export default App;