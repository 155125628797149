
import {
	ERC20ContractParamsType,
	ChainParamsType,
	WrappedTokenType,
} from '../models/BlockchainAdapter';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

// ---------- NAVIGATION ----------
export const gotoMain = () => {
	return {
		type: 'GOTO_MAIN',
	}
}
export const gotoPreview = () => {
	return {
		type: 'GOTO_PREVIEW',
	}
}
export const gotoList = () => {
	return {
		type: 'GOTO_LIST',
	}
}
export const setLoading = (payload: { msg: string }) => {
	return {
		type: 'SET_LOADING',
		payload,
	}
}
export const unsetLoading = () => {
	return {
		type: 'UNSET_LOADING',
	}
}
export const setError = (payload: {
	text: string,
	buttons: undefined | Array<{
		text: string,
		clickFunc: Function,
	}>,
	links: undefined | Array<{
		text: string,
		url : string,
	}>
}) => {
	return {
		type: 'SET_ERROR',
		payload,
	}
}
export const clearError = () => {
	return {
		type: 'CLEAR_ERROR',
	}
}
export const setInfo = (payload: {
	text: string,
	buttons: undefined | Array<{
		text: string,
		clickFunc: Function,
	}>,
	links: undefined | Array<{
		text: string,
		url : string,
	}>
}) => {
	return {
		type: 'SET_INFO',
		payload,
	}
}
export const clearInfo = () => {
	return {
		type: 'CLEAR_INFO',
	}
}
export const resetAppData = () => {
	return {
		type: 'RESET_APP_DATA',
	}
}
export const gotoListRequest = () => {
	return {
		type: 'GOTO_LIST_REQUEST',
	}
}
export const gotoListResolve = () => {
	return {
		type: 'GOTO_LIST_RESOLVE',
	}
}
export const gotoListReject = () => {
	return {
		type: 'GOTO_LIST_REJECT',
	}
}
// ---------- END NAVIGATION ----------

// ---------- CONNECTION ----------
export const metamaskConnectionSuccess = (payload: { address: string }) => {
	return {
		type: 'METAMASK_CONNECTION_SUCCESS',
		payload,
	}
}
export const metamaskConnectionNotInstalled = () => {
	return {
		type: 'METAMASK_CONNECTION_NOT_INSTALLED',
	}
}
export const metamaskConnectionRejected = () => {
	return {
		type: 'METAMASK_CONNECTION_REJECTED',
	}
}
export const metamaskSetChainParams = (
	payload: {
		chainId?             : number | undefined,
		chainName?           : string,
		chainRPCUrl?         : string,
		networkTokenTicket?  : string,
		EIPPrefix?           : string,
		networkTokenDecimals?: number,
		networkTokenIcon?    : string,
		isTestNetwork?       : Boolean,
		explorerBaseUrl?     : string,
		explorerName?        : string,
		secondsPerBlock?     : number,
	}
) => {
	return {
		type: 'METAMASK_SET_CHAIN_PARAMS',
		payload,
	}
}
export const metamaskSetAvailableChains = ( payload: Array<ChainParamsType> ) => {
	return {
		type: 'METAMASK_SET_AVAILABLE_CHAINS',
		payload,
	}
}
export const setAuthMethod = ( payload: string ) => {
	return {
		type: 'SET_AUTH_METHOD',
		payload,
	}
}
export const unsetAuthMethod = () => {
	return {
		type: 'UNSET_AUTH_METHOD',
	}
}
export const setWrongChain = ( payload: {
	chainId: number,
	chainName: string,
} ) => {
	return {
		type: 'SET_WRONG_CHAIN',
		payload,
	}
}
export const setUrlNotFound = () => {
	return {
		type: 'SET_URL_NOT_FOUND',
	}
}
// ---------- END CONNECTION ----------

// ---------- NATIVE TOKEN ----------
export const updateNativeBalance = ( payload: { balance: BigNumber } ) => {
	return {
		type: 'UPDATE_NATIVE_BALANCE',
		payload,
	}
}
// ---------- END NATIVE TOKEN ----------

// ---------- ERC20 TOKEN ----------
export const updateERC20Token = ( payload: ERC20ContractParamsType ) => {
	return {
		type: 'UPDATE_ERC20_TOKEN',
		payload,
	}
}
export const updateTradableERC20Token = ( payload: ERC20ContractParamsType ) => {
	return {
		type: 'UPDATE_TRADABLE_ERC20_TOKEN',
		payload,
	}
}
// ---------- END ERC20 TOKEN ----------

// ---------- WRAPPED TOKENS ----------
export const updateWrappedTokens = ( payload: Array<WrappedTokenType> ) => {
	return {
		type: 'UPDATE_WRAPPED_TOKENS',
		payload,
	}
}
export const updateOneWrappedToken = ( payload: WrappedTokenType ) => {
	return {
		type: 'UPDATE_ONE_WRAPPED_TOKEN',
		payload,
	}
}
export const removeOneWrappedToken = ( payload: { tokenId: string } ) => {
	return {
		type: 'REMOVE_ONE_WRAPPED_TOKEN',
		payload,
	}
}
export const wrappedTokensLoadStart = () => {
	return {
		type: 'WRAPPED_TOKENS_LOAD_START',
	}
}
export const wrappedTokensLoadEnd = () => {
	return {
		type: 'WRAPPED_TOKENS_LOAD_END',
	}
}
// ---------- END WRAPPED TOKENS ----------