
import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export const tokenToFloat = (value: BigNumber, decimals: number): BigNumber => {
	return value.multipliedBy( 10**-decimals );
}

export const tokenToInt = (value: BigNumber, decimals: number): BigNumber => {
	return value.multipliedBy( 10**decimals );
}

export const compactAddress = (address: string | undefined): string => {
	if ( !address ) { return '' }

	return `${address.slice(0,5)}...${address.slice(-5)}`
}

export const addThousandSeparator = ( numStr: string ): string => {
	const parts = numStr.split(".");
	return parts[0]
		.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
		+ (parts[1] ? "." + parts[1] : "")
		+ ( numStr.endsWith('.') ? '.' : '' );
}
export const removeThousandSeparator = ( numStr: string ): string => {
	return numStr.replaceAll(',', '.').replaceAll(' ', '');
}
export const zeroedTime = ( num: number ) => {
	if ( num === undefined ) { return '' }
	return num < 10 ? `0${num}` : `${num}`
}

export const monthesNames = [
	'jan',
	'feb',
	'mar',
	'apr',
	'may',
	'june',
	'july',
	'aug',
	'sept',
	'oct',
	'nov',
	'dec',
];