
import React                   from 'react';
import { CopyToClipboard }     from 'react-copy-to-clipboard';
import Blockies                from 'react-blockies';
import {
	MetamaskAdapter,
} from '../../models/BlockchainAdapter';
import {
	clearError,
	gotoListRequest,
	unsetAuthMethod,
	unsetLoading,
} from '../../reducers';
import {
	Link,
	withRouter,
	match
} from 'react-router-dom';
import { tokenToFloat }        from '../../models/_utils';
import * as H from 'history';

import icon_logo      from '../../static/pics/sr-logo.png';
import icon_attention from '../../static/pics/i-attention-red.svg'
import icon_copy      from '../../static/pics/i-copy.svg'

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type HeaderProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function,
	match                 : match;
	location              : H.Location,
	history               : H.History,
}
type HeaderState = {
	address            : string,
	metamaskLogged     : boolean,
	isTestNetwork      : boolean,
	chainName          : string,

	balanceNative : BigNumber,
	decimalsNative: number,
	symbolNative  : string,

	copiedHint: boolean,
}

class Header extends React.Component<HeaderProps, HeaderState> {

	store                 : any;
	metamaskAdapter       : MetamaskAdapter;
	unsubscribe!          : Function;
	showAuthMethodSelector: Function;

	constructor(props: HeaderProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.showAuthMethodSelector = props.showAuthMethodSelector;

		this.state = {
			address            : '',
			metamaskLogged     : false,
			isTestNetwork      : false,
			chainName          : this.store.getState().metamaskAdapter.chainName,

			balanceNative : this.store.getState().account.balanceNative,
			decimalsNative: this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative  : this.store.getState().metamaskAdapter.networkTokenTicket,

			copiedHint    : false,
		};
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {
			this.setState({
				address        : this.store.getState().account.address,
				metamaskLogged : this.store.getState().metamaskAdapter.logged,
				isTestNetwork  : this.store.getState().metamaskAdapter.isTestNetwork,
				chainName      : this.store.getState().metamaskAdapter.chainName,

				balanceNative : this.store.getState().account.balanceNative,
				decimalsNative: this.store.getState().metamaskAdapter.networkTokenDecimals,
				symbolNative  : this.store.getState().metamaskAdapter.networkTokenTicket,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	getBalances() {
		if ( !this.state.balanceNative || !this.state.decimalsNative || !this.state.symbolNative ) { return '' }
		return `${ tokenToFloat(new BigNumber(this.state.balanceNative), this.state.decimalsNative).toFixed(3, BigNumber.ROUND_DOWN) } ${ this.state.symbolNative }`

	}
	getConnectBtn() {
		return (
			<button
				className="btn btn-sm btn-outline"
				onClick={(e) => {
					this.store.dispatch(unsetLoading());
					this.store.dispatch(clearError());
					this.store.dispatch(unsetAuthMethod());
					this.store.dispatch(gotoListRequest());

					this.metamaskAdapter.connect();
				}}
			>
				connect wallet
			</button>
		)
	}
	getUserData() {
		return (
			<React.Fragment>
				<button
					className="btn btn-sm btn-outline"
					style={{ marginRight: '15px' }}
					onClick={(e) => {
						localStorage.removeItem('provider_type');
						window.location.reload();
					}}
				>
					disconnect
				</button>
				<div className="s-user__data">
					<div className="info">{ this.getBalances() }</div>
					<CopyToClipboard
						text={ this.state.address }
						onCopy={() => {
							this.setState({ copiedHint: true });
							setTimeout(() => { this.setState({ copiedHint: false }); }, 5*1000);
						}}
					>
						<button className="btn-copy">
							<span>{ this.state.address ? `${this.state.address.slice(0,5)}...${this.state.address.slice(-5)}` : '' }</span>
							<img src={ icon_copy } alt="" />
							<span className="btn-action-info" style={{display: this.state.copiedHint ? 'block' : 'none' }}>Copied</span>
						</button>
					</CopyToClipboard>
				</div>
				<div className="s-user__avatar">
					<div className="img">
						<Blockies
							seed      = { this.state.address }
							size      = {5}
							scale     = {10}
							color     = "#141616"
							bgColor   = "#0bf5fe"
							spotColor = "#ffffff"
						/>
					</div>
				</div>
			</React.Fragment>
		)
	}
	getBtnOrData() {
		if ( !this.state.metamaskLogged ) {
			return this.getConnectBtn()
		} else {
			return this.getUserData()
		}
	}
	getTestLabel() {
		if ( this.state.isTestNetwork ) {
			return (
				<div className="s-header__network">
					<img src={ icon_attention } alt="" />
					<span>Works in { this.state.chainName } test network</span>
				</div>
			)
		} else {
			return (
				<div className="s-header__network">
					<span className="text-blue">Works in { this.state.chainName } network</span>
				</div>
			)
		}
	}

	render() {
		return (
			<header className="s-header">
			<div className="container">
				<div>
					<Link className="s-header__logo" to="/">
						<img src={ icon_logo } alt="ENVELOP" />
					</Link>
					{ this.getTestLabel() }
				</div>
				<div className="s-user">
					{ this.getBtnOrData() }
				</div>
			</div>
			</header>

		)
	}
}

export default withRouter(Header);