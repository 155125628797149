
import {
	ERC20ContractParamsType,
	ChainParamsType,
	WrappedTokenType,
} from '../models/BlockchainAdapter';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type StateType = {
	currentPage      : string,
	_loading         : string,
	_error : undefined | {
		text   : string,
		buttons: undefined | Array<{
			text     : string,
			clickFunc: Function,
		}>,
		links: undefined | Array<{
			text: string,
			url : string,
		}>,
	},
	_info : undefined | {
		text   : string,
		buttons: undefined | Array<{
			text     : string,
			clickFunc: Function,
		}>,
		links: undefined | Array<{
			text: string,
			url : string,
		}>,
	},
	metamaskAdapter: {
		logged              : boolean,
		metamaskNotInstalled: boolean,
		permissionRejected  : boolean,
		chainId             : Number,
		availableChains     : Array<ChainParamsType>,
		authMethod          : string,
		secondsPerBlock     : number,
		wrongChain          : {
			chainId: number,
			chainName: string,
		} | undefined,
		urlNotFound: boolean,
	},
	account: {
		address: string,
		balanceNative: BigNumber,
	},
	erc20Tokens: Array<ERC20ContractParamsType>,
	wrappedTokens: Array<WrappedTokenType>,
};

export const initialState: StateType = {
	currentPage      : '',
	_loading         : '',
	_error           : undefined,
	_info            : undefined,
	account          : {
		address      : '',
		balanceNative: new BigNumber(0),
	},
	metamaskAdapter: {
		logged              : false,
		metamaskNotInstalled: false,
		permissionRejected  : false,
		chainId             : 0,
		availableChains     : [],
		authMethod          : '',
		secondsPerBlock     : 0,
		wrongChain          : undefined,
		urlNotFound         : false,
	},
	erc20Tokens: [],
	wrappedTokens: [],
}

export const reducer = (state = initialState, action: any): StateType => {

	switch ( action.type ) {

		// ---------- NAVIGATION ----------
		case 'GOTO_MAIN': {
			return {
				...state,
				currentPage: '',
			}
		}
		case 'GOTO_PREVIEW': {
			return {
				...state,
				currentPage: 'preview',
			}
		}
		case 'GOTO_LIST': {
			return {
				...state,
				currentPage: 'list',
			}
		}
		case 'SET_LOADING': {
			return {
				...state,
				_loading: action.payload.msg,
			}
		}
		case 'UNSET_LOADING': {
			return {
				...state,
				_loading: '',
			}
		}
		case 'SET_ERROR': {
			return {
				...state,
				_error: action.payload,
			}
		}
		case 'CLEAR_ERROR': {
			return {
				...state,
				_error: undefined,
			}
		}
		case 'SET_INFO': {
			return {
				...state,
				_info: action.payload,
			}
		}
		case 'CLEAR_INFO': {
			return {
				...state,
				_info: undefined,
			}
		}
		case 'RESET_APP_DATA': {
			return {
				...initialState,
				metamaskAdapter: {
					...initialState.metamaskAdapter,
					authMethod: state.metamaskAdapter.authMethod,
				}
			};
		}
		// ---------- END NAVIGATION ----------
		// ---------- CONNECTION ----------
		case 'METAMASK_CONNECTION_SUCCESS': {
			return {
				...state,
				_loading: '',
				metamaskAdapter: {
					...state.metamaskAdapter,
					metamaskNotInstalled: false,
					permissionRejected  : false,
					logged              : true,
				},
				account: {
					...state.account,
					address: action.payload.address,
				}
			}
		}
		case 'METAMASK_CONNECTION_NOT_INSTALLED': {
			return {
				...state,
				_loading: '',
				metamaskAdapter: {
					...initialState.metamaskAdapter,
					metamaskNotInstalled: true,
				}
			}
		}
		case 'METAMASK_CONNECTION_REJECTED': {
			return {
				...state,
				_loading: '',
				metamaskAdapter: {
					...initialState.metamaskAdapter,
					permissionRejected: true,
				}
			}
		}
		case 'METAMASK_SET_CHAIN_PARAMS': {
			return {
				...state,
				metamaskAdapter: {
					...state.metamaskAdapter,
					...action.payload,
				}
			}
		}
		case 'SET_AUTH_METHOD': {
			return {
				...state,
				metamaskAdapter: {
					...state.metamaskAdapter,
					authMethod: action.payload,
				}
			}
		}
		case 'UNSET_AUTH_METHOD': {
			return {
				...state,
				metamaskAdapter: {
					...state.metamaskAdapter,
					authMethod: '',
				}
			}
		}
		case 'SET_WRONG_CHAIN': {
			return {
				...state,
				metamaskAdapter: {
					...state.metamaskAdapter,
					wrongChain: action.payload,
				}
			}
		}
		case 'SET_URL_NOT_FOUND': {
			return {
				...state,
				metamaskAdapter: {
					...state.metamaskAdapter,
					urlNotFound: true,
				}
			}
		}
		// ---------- END CONNECTION ----------

		// ---------- NATIVE TOKEN ----------
		case 'UPDATE_NATIVE_BALANCE': {
			return {
				...state,
				account: {
					...state.account,
					balanceNative: action.payload.balance,
				}
			}
		}
		// ---------- END NATIVE TOKEN ----------

		// ---------- ERC20 TOKEN ----------
		case 'UPDATE_ERC20_TOKEN': {
			return {
				...state,
				erc20Tokens: [
					...state.erc20Tokens.filter((item) => { return item.address !== action.payload.address }),
					action.payload,
				]
			}
		}
		// ---------- END ERC20 TOKEN ----------

		// ---------- WRAPPED TOKEN ----------
		case 'UPDATE_WRAPPED_TOKENS': {
			return {
				...state,
				wrappedTokens: action.payload,
			}
		}
		case 'UPDATE_ONE_WRAPPED_TOKEN': {
			return {
				...state,
				wrappedTokens: [
					...state.wrappedTokens.filter((item) => { return item.tokenId !== action.payload.tokenId }),
					action.payload,
				]
			}
		}
		case 'REMOVE_ONE_WRAPPED_TOKEN': {
			return {
				...state,
				wrappedTokens: [
					...state.wrappedTokens.filter((item) => { return item.tokenId !== action.payload.tokenId }),
				]
			}
		}
		// ---------- END WRAPPED TOKEN ----------

		default: { return state }

	}
}