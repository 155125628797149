
import Web3         from 'web3';
import { Contract } from "web3-eth-contract";
import erc20_abi    from '../../abis/_erc20.json';
import {
	updateERC20Token,
} from '../../reducers';

import default_icon from '../../static/pics/coins/_default.png';

import BigNumber from 'bignumber.js';
import WrappedTokenDispatcher from './wrappedtoken';
import { MetamaskAdapter } from '.';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type ERC20ContractPropsType = {
	web3            : Web3,
	store           : any,
	contractAddress : string,
	userAddress     : string,
	erc20Type       : string,
	allowanceAddress: string,
	wrapperContract : WrappedTokenDispatcher,
	metamaskAdapter : MetamaskAdapter,
}
export type ERC20ContractParamsType = {
	address  : string,
	icon     : string,
	name     : string,
	symbol   : string,
	decimals : number | undefined,
	balance  : BigNumber,
	allowance: BigNumber,
	periods  : Array<{ period: BigNumber, apy: BigNumber }>
}

export default class ERC20Contract {

	web3            : Web3;
	store           : any;
	contractAddress : string;
	userAddress     : string;
	erc20Type       : string;
	contract        : Contract;
	erc20Params!    : ERC20ContractParamsType;
	allowanceAddress: string;
	wrapperContract : WrappedTokenDispatcher;
	metamaskAdapter : MetamaskAdapter;

	constructor(props: ERC20ContractPropsType) {
		this.web3             = props.web3;
		this.store            = props.store;
		this.contractAddress  = props.contractAddress;
		this.userAddress      = props.userAddress;
		this.erc20Type        = props.erc20Type;
		this.allowanceAddress = props.allowanceAddress;
		this.wrapperContract  = props.wrapperContract;
		this.metamaskAdapter  = props.metamaskAdapter;

		this.contract = new this.web3.eth.Contract(erc20_abi as any, this.contractAddress);
		// console.log('erc20', this.contract);
		this.addCheckoutEventListener();
	}

	async getParams() {
		const name      = await this.contract.methods.name().call();
		const symbol    = await this.contract.methods.symbol().call();
		const decimals  = await this.contract.methods.decimals().call();
		const balance   = new BigNumber(await this.contract.methods.balanceOf(this.userAddress).call());
		const allowance = new BigNumber(await this.contract.methods.allowance(this.userAddress, this.allowanceAddress).call());

		const periodsRaw = await this.wrapperContract.wrapperContract.methods.getRewardSettings(this.contractAddress).call()
		const periods    = periodsRaw.map((item: any) => { return { period: new BigNumber(item.period).multipliedBy(1000), apy: new BigNumber(item.rewardPercent).dividedBy(100) } })

		let icon = default_icon;
		try { icon = require(`../../static/pics/coins/${symbol.toLowerCase()}.jpeg`).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${symbol.toLowerCase()}.jpg` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${symbol.toLowerCase()}.png` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${symbol.toLowerCase()}.svg` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${this.contractAddress}.jpeg`).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${this.contractAddress}.jpg` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${this.contractAddress}.png` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${this.contractAddress}.svg` ).default } catch (ignored) {}

		this.erc20Params = {
			address: this.contractAddress,
			name,
			symbol,
			decimals,
			icon,
			balance,
			allowance,
			periods,
		}

		this.store.dispatch(updateERC20Token(this.erc20Params));
	}
	addCheckoutEventListener() {
		this.contract.events.Approval(
			{
				fromBlock: 'earliest'
			},
			(e: any, data: any) => {
				this.metamaskAdapter.updateBalances();
			}
		);
		this.contract.events.Transfer(
			{
				fromBlock: 'earliest'
			},
			(e: any, data: any) => {
				this.metamaskAdapter.updateBalances();
			}
		);
	}
	async getBalance() {
		if ( !this.erc20Params || !this.erc20Params.address ) { return; }
		const balance   = new BigNumber(await this.contract.methods.balanceOf(this.userAddress).call());
		const allowance = new BigNumber(await this.contract.methods.allowance(this.userAddress, this.allowanceAddress).call());

		this.erc20Params = {
			...this.erc20Params,
			balance,
			allowance,
		}

		this.store.dispatch(updateERC20Token(this.erc20Params));
	}

	makeAllowance(amount: BigNumber) {
		const parsedAmount = new BigNumber(amount).toString() === '-1' ? new BigNumber(10**50).toString() : new BigNumber(amount).toString();
		return this.contract.methods.approve(this.allowanceAddress, parsedAmount).send({ from: this.userAddress });
	}

}